<template>
    <div>
        <div class="page-title">服务类型设置</div>

        <a-spin :spinning="listLoading">
            <div>
                <table class="type-table">
                    <thead>
                    <tr>
                        <th class="st">服务类型</th>
                        <th>服务说明</th>
                        <th class="sb"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(item,tk) in types">
                        <tr :key="tk">
                            <td>
                                {{ item.name }}
                            </td>
                            <td class="summary">
                                {{ item.summary }}
                            </td>
                            <td>
                                <div class="op">
                                    <a @click="editType(item,tk)" class="btn-tb input-after-edit"></a>
                                    <a @click="delType(tk)" class="btn-tb input-after-delete"></a>
                                </div>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colspan="3">
                            <button @click="addType" class="btn-add">添加服务类型</button>
                        </td>
                    </tr>
                    </tfoot>
                </table>

                <button @click="confirmSaveAll" class="btn btn-submit">提交</button>
            </div>
        </a-spin>

        <transition name="slide-fade">
            <div v-if="edit" class="edit-container">
                <div v-if="edit" class="ec-content">
                    <div class="ec-title">编辑</div>
                    <div class="e-title">服务类型</div>
                    <div class="e-ed">
                        <at-input v-model="selectedItem.name" width="340px"/>
                    </div>
                    <div class="e-title">服务说明</div>
                    <div class="e-ed">
                        <textarea v-model="selectedItem.summary"/>
                    </div>

                    <div class="ec-op">

                        <button @click="editCancel" class="ec-op-white">取消</button>
                        <button @click="editSave" class="ec-op-black">保存</button>

                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import AtInput from "../../../components/AtInput";
    import api from "../../../repo/api";

    export default {
        name: "Type",
        components: {AtInput},
        data() {
            return {
                types: [],
                selectedItem: null,
                edit: false,
                editIdx: null,
                listLoading: true,
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                this.listLoading = true

                api.get('/course/service-types', (data) => {
                    this.listLoading = false

                    this.types = data.data || []
                })
            },
            delType(idx) {
                this.types.splice(idx, 1)
            },
            addType() {
                this.editType({'name': '', 'summary': ''}, -1);
            },
            editType(item, idx) {
                this.selectedItem = JSON.parse(JSON.stringify(item));
                this.editIdx = idx
                this.edit = true
            },
            editSave() {
                if (this.editIdx >= 0) {
                    this.types.splice(this.editIdx, 1, this.selectedItem)

                } else {
                    this.types.push(this.selectedItem)
                }

                this.edit = false
            },
            editCancel() {
                this.edit = false
            },
            confirmSaveAll() {

                this.$loading.show()

                api.post('/course/sync-service-types', {types: this.types}, (data) => {

                    this.$message.data(data)
                    this.$loading.hide()

                    if (data.code === 0) {
                        this.getList()
                    }

                })
            }
        }
    }

</script>

<style lang="less" scoped>
    .st {
        width: 280px;
    }

    .sb {
        width: 90px;
        text-align: right;
    }

    .type-table {
        width: 100%;
        max-width: 1000px;

        td, th {
            height: 48px;
            border-bottom: 1px solid #EEEEEE;
        }

        td {
            font-size: 14px;
            font-weight: 400;
            color: #606266;
            line-height: 24px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-right: 10px;

            div {
                color: #606266;
            }
        }

        th {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
        }

        tr:hover {
            .op {
                opacity: 1;
            }
        }
    }

    .op {
        opacity: 0;
        transition: all .3s;
    }

    .btn-tb {
        display: inline-block;
        width: 30px;
        height: 30px;

        &:first-child {
            margin-right: 20px;
        }
    }

    .edit-container {
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        position: fixed;

        .ec-content {
            position: absolute;
            width: 392px;
            left: 50%;
            margin-left: -196px;
            top: 20%;
            background-color: white;
            padding: 24px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
            border: 1px solid #EEEEEE;
            border-radius: 10px;
        }


        .ec-title {
            margin-bottom: 27px;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #303133;
            line-height: 14px;
        }

        .e-title {
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
        }

        .e-ed {
            margin-bottom: 24px;
        }
    }

    textarea {
        width: 100%;
        height: 127px;
        border: 1px solid #EEEEEE;
        resize: none;
        padding: 10px;
        line-height: 22px;
        outline: none;

        &:focus {
            border-color: #333333;
        }
    }

    .ec-op {
        display: flex;
        justify-content: center;
        width: 100%;

        .ec-op-white {
            margin-right: 22px;
        }

        .ec-op-black {
            background: #333333;
            color: #FFFFFF;
        }

        button {
            width: 64px;
            height: 28px;
            background: #FFFFFF;
            border-radius: 100px;
            border: 1px solid #DCDFE6;
            font-size: 12px;
            font-weight: 400;
            color: #5A5E66;
            cursor: pointer;
        }
    }

    .btn-add {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        padding-left: 26px;
        border: none;
        background-color: transparent;
        cursor: pointer;

        background-image: url("/assets/icons/btn-cy.png");
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: left;

        height: 30px;

    }

    .btn-submit {
        margin-top: 88px;
        width: 82px;
        height: 32px;
        background: #333333;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 32px;
        padding: 0;
    }
</style>